/* eslint-disable no-restricted-imports */
import { Col, Divider, Progress, Row, Tag } from 'antd'
import {
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { showErrorMessage } from '../../../actions/notification'
import makeRequest from '../../../libs/request'

export default function BillingComponent({ value, show }) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalRows, setTotalRows] = useState(0)
  const [rows, setRow] = useState([])
  const [plansInfo, setPlansInfo] = useState()
  const [card, setCard] = useState({})
  let price = {
    permissions: [
      'company.1',
      'certification.2',
      'analytics_dashboard',
      'prefixed_timer',
      'reminders_per_cert.3',
      'storage_size.1',

      'certification.10',
      'configurable_timer',
      // 'reminders_per_cert.20',
      'reminders_per_cert.unlimited',
      'documents_upload_per_cert.15',
      'storage_size.5',
      'create_team',
      'team_notifications.12',
      'public_report_per_company.5',
      'add_on_company',
    ],
  }
  let isCompanyBase = value?.is_base
  const isFree = value?.plan_name === 'Free'

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const checkShowPermissions = (value) => {
    if (price?.permissions) {
      return price?.permissions?.find((i) => i === value) ? true : false
    }
    return false
  }

  const getData = () => {
    makeRequest('get', `icpa/companies/${value?._id}/cards`)
      .then(({ data }) => {
        if (data.signal) {
          setCard(data.data)
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    makeRequest('get', `icpa/plans/get-info/companies/${value?._id}`)
      .then(({ data }) => {
        if (data.signal) {
          setPlansInfo({
            expires_on: data.data?.expires_on
              ? moment(data.data?.expires_on).format('DD/MM/YYYY')
              : null,
            next_bill_on: data.data?.next_bill_on
              ? moment(data.data?.next_bill_on).format('DD/MM/YYYY')
              : null,
          })
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getInvoicesList = async (page = 0, rowsPerPage = 10) => {
    let invoicesList = null
    makeRequest('get', `icpa/invoices/get-all`, {
      company_id: value?._id,
      page: page + 1,
      limit: rowsPerPage,
    })
      .then(({ data }) => {
        if (data.signal) {
          let itemList = data.data
          setRow(itemList?.items ?? [])
          setTotalRows(itemList?.total ?? 0)
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    if (invoicesList) {
    }
  }

  useEffect(() => {
    if (show) {
      getData()
      getInvoicesList()
    }
  }, [value, show])

  useEffect(() => {
    if (show) {
      getInvoicesList(page, rowsPerPage)
    }
  }, [page, rowsPerPage])

  let renderStatus = (status) => {
    if (status === 'paid') {
      return (
        <Tag bordered={false} className='ml-8 tag-marking-progress-2 mr-0'>
          Paid
        </Tag>
      )
    } else if (status === 'open') {
      return (
        <Tag bordered={false} className='ml-8 tag-draft mr-0'>
          Open
        </Tag>
      )
    } else if (status === 'overdue') {
      return (
        <Tag bordered={false} className='ml-8 tag-exam-completed-2 mr-0'>
          Overdue
        </Tag>
      )
    } else {
      return (
        <Tag bordered={false} className='ml-8 tag-draft mr-0'>
          {status}
        </Tag>
      )
    }
  }

  const PermissionsComponent = ({ title }) => {
    return (
      <Col className='gutter-row' span={8}>
        <div className='d-flex mt-8'>
          <div className='icon-dot'>
            <svg
              width='26'
              height='27'
              viewBox='0 0 26 27'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M13 26.5C20.1799 26.5 26 20.6799 26 13.5C26 6.3201 20.1799 0.5 13 0.5C5.8201 0.5 0 6.3201 0 13.5C0 20.6799 5.8201 26.5 13 26.5Z'
                fill='#99A4C0'
              />
              <path
                d='M7.1167 14.3406L10.4785 17.7024L18.8831 9.29785'
                stroke='white'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </div>
          <div
            className='ml-14'
            style={{
              fontSize: '18px',
              color: '#0F1117',
            }}
          >
            {title}
          </div>
        </div>
      </Col>
    )
  }

  return (
    <>
      <div className='mt-32'>
        <div
          style={{
            padding: '20px',
            borderRadius: '12px',
            minHeight: '214px',
            background: '#FFFFFF',
          }}
        >
          {isFree ? (
            <div className='row-sb'>
              <div className='d-flex align-items-center'>
                <div
                  className={`d-flex align-items-center justify-content-center icon-active`}
                >
                  <img src={'/images/free.svg'} />
                </div>
                <div className='f-semibold_24-20 ml-16'>Free plan</div>
              </div>
            </div>
          ) : (
            <div className='row-sb'>
              <div className='d-flex align-items-center'>
                <div
                  className={`d-flex align-items-center justify-content-center icon-active`}
                >
                  <img src={'/images/shield.svg'} />
                </div>
                <div>
                  <div className='f-semibold_24-20 ml-16'>
                    {' '}
                    {isCompanyBase ? 'Pro Plan - Default' : 'Add-on'}
                  </div>
                  <div className='f-medium_20 color-grey-500 ml-16'>
                    SGD {isCompanyBase ? '$69.99' : '$59.99'}{' '}
                    {plansInfo?.expires_on
                      ? `• Expires on ${plansInfo?.expires_on}`
                      : ''}{' '}
                    {plansInfo?.next_bill_on
                      ? `• Next bill on ${plansInfo?.next_bill_on}`
                      : ''}
                  </div>
                </div>
              </div>
            </div>
          )}

          <Divider style={{ border: '1px solid #C0C7D8' }} />
          <Row gutter={24}>
            {checkShowPermissions('company.1') && (
              <PermissionsComponent title={'1 Company'} />
            )}
            {checkShowPermissions('certification.2') && isFree && (
              <PermissionsComponent title={'2 Certification'} />
            )}
            {checkShowPermissions('certification.10') && !isFree && (
              <PermissionsComponent title={'10 Certification'} />
            )}
            {checkShowPermissions('analytics_dashboard') && (
              <PermissionsComponent title={'Analytics dashboard'} />
            )}
            {checkShowPermissions('prefixed_timer') && isFree && (
              <PermissionsComponent title={'Prefixed timer'} />
            )}
            {checkShowPermissions('configurable_timer') && !isFree && (
              <PermissionsComponent title={'Configurable timer'} />
            )}
            {checkShowPermissions('reminders_per_cert.3') && isFree && (
              <PermissionsComponent title={'Up to 3 reminder per cert'} />
            )}
            {checkShowPermissions('reminders_per_cert.20') && !isFree && (
              <PermissionsComponent title={'20 Reminders per cert'} />
            )}
            {checkShowPermissions("reminders_per_cert.unlimited") && !isFree && (
              <PermissionsComponent title={"Unlimited reminders per cert"} />
            )}
            {checkShowPermissions('documents_upload_per_cert.15') &&
              !isFree && (
                <PermissionsComponent
                  title={'Unlimited document upload per cert'}
                />
              )}
            {checkShowPermissions('storage_size.1') && isFree && (
              <PermissionsComponent title={'1 GB Storage Size'} />
            )}
            {checkShowPermissions('create_team') && !isFree && (
              <PermissionsComponent title={'Create Your Team'} />
            )}
            {checkShowPermissions('team_notifications.12') && !isFree && (
              <PermissionsComponent title={'Team notifications (Max 12 Pax)'} />
            )}
            {checkShowPermissions('storage_size.5') && !isFree && (
              <PermissionsComponent title={'5 GB Storage Size'} />
            )}
            {/* {checkShowPermissions('public_report_per_company.5') && !isFree && (
              <PermissionsComponent
                title={'5 Public Report for Stakeholders per company'}
              />
            )} */}
            {checkShowPermissions('add_on_company') && !isFree && (
              <PermissionsComponent
                title={
                  'Add-on Company / Site(s) for synchronization under Pro plan with same features at $59.99.'
                }
              />
            )}
          </Row>
          {isFree ? (
            <></>
          ) : (
            <div
              style={{
                borderRadius: '12px',
                padding: '16px',
                minHeight: '88px',
                background: '#F4F7FE',
              }}
              className='rowy-center mt-16'
            >
              <div className='d-flex align-items-center justify-content-between width-full'>
                <div className='d-flex align-items-center justify-content-center'>
                  <div className='row-center'>
                    <img src={'/images/visa-icon.svg'} />
                  </div>
                  <div>
                    <div className='f-medium_20 color-grey-500 ml-16'>
                      {card?.card_number}
                    </div>
                    <div className='f-medium_20 color-grey-500 ml-16'>
                      Expires on {card?.expiry_date ? card?.expiry_date : ''}
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-center'></div>
              </div>
            </div>
          )}
        </div>
        {isFree ? (
          <></>
        ) : (
          <div
            style={{
              padding: '20px',
              borderRadius: '12px',
              background: '#FFFFFF',
              marginTop: '24px',
            }}
            className='table-custom'
          >
            <div className='f-semibold_36 black-color mt-32 mb-12'>
              Billing History
            </div>
            <Table>
              <TableHead>
                <TableRow className='default-black-color'>
                  <TableCell>Invoice</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Billing Date</TableCell>
                  <TableCell>Plan</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length ? (
                  rows.map((row, key) => (
                    <TableRow key={`order-${row.id}-${key}`}>
                      <TableCell>{row?.identifier}</TableCell>
                      <TableCell>${row?.amount ?? 0}</TableCell>
                      <TableCell>
                        {row?.bill_date
                          ? moment(row?.bill_date).format('DD/MM/YYYY')
                          : ''}
                      </TableCell>
                      <TableCell>
                        {row?.plan_name === 'Pro'
                          ? 'Pro Plan - Default'
                          : row?.plan_name === 'Free'
                            ? 'Free Plan'
                            : row?.plan_name}
                      </TableCell>
                      <TableCell>
                        {renderStatus(row?.stripe_invoice_status)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
            {totalRows > 10 && (
              <TablePagination
                className='table-pagination-customer'
                labelRowsPerPage={`Row per page`}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}-${to} of ${count}`
                }}
                rowsPerPageOptions={[10, 20, 50]}
                component='div'
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}
